import React from "react";
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import LifeEvent from "./LifeEvent";
import { Chip } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import {planetColors, planetForeColors, kaaragans} from '../common/Planets'
import Button from '@material-ui/core/Button';
import { parseEventDate } from "../DasaBhukthi/DasaUtil";
import { withRouter } from "react-router-dom";
import { formatDatePickerToDate, formatToKotcharam } from "../common/DateUtil";

class LifeEvents extends React.Component {

   tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  formatDate = dt => moment(Date.parse(dt)).format('DD-MMM-YY HH:mm a');
  format = msg => {console.log("msg :", msg); return msg?this.props.intl.formatMessage({id:msg}): "";}

  getBond = f => {
    if (f === "NORMAL") {
      return "=";
    }
    if (f === "OPEN") {
      return "+";
    }
    if (f === "CLOSED") {
      return "x";
    }
  }

  getPlanetShortName = (name) => name + "_SHORT";

  getColor = p => planetColors[p];
  getForeColor = p => planetForeColors[p];

  getPlanet = (d) => {
    // console.log("Pu :", d.putthis);
    return <span>
    <Chip label= {<div><FormattedMessage id={this.getPlanetShortName(d.planet)} /><span>{this.getBond(d.flow)}</span></div> }
      style={{margin: '4px', width: '50px', backgroundColor: this.getColor(d.planet), color : this.getForeColor(d.planet), border: '1px solid grey' }}/>
    </span>
  }
  getBlankOverall = (d) => {
    return <span>
    <Chip label= {<span>{this.getBond(d.flow)}</span> }
      style={{margin: '4px', width: '50px', backgroundColor: this.getColor(d.flow), color : this.getForeColor(d.flow), border: '1px solid grey' }}/>
    </span>
  }

  handleDateClicked = (d) => {
    let date = parseEventDate(d.target.textContent);
		this.props.raasiActions.retrieveKotcharam(formatToKotcharam(date));//08-Jun-21 21:18 pm

    if(this.props.user){
      this.props.raasiActions.getDasaByDate(this.props.user.id, formatDatePickerToDate(date));
    }
    this.props.history.push("/kattam");
  }

  getChip = d => <Chip label={<div><FormattedMessage id={this.getPlanetShortName(d.planet)} /><span>{this.getBond(d.flow)}</span></div>}
  style={{ margin: '4px', width: '50px', backgroundColor: this.getColor(d.planet), color: this.getForeColor(d.planet), border: '1px solid grey' }} />;

  getPlanetOfAll = (d, e) => {
    // console.log("Pu :", d.putthis);
    let s = d.putthis[0].andharam[0].sootchamam[0];
    let a = d.putthis[0].andharam[0];
    let p = d.putthis[0];

    return <div style={{ display: 'flex', flexDirection: "column", fontSize: "x-small" }}>
      <div><span>
        {this.getChip(d)}
      </span>
        <span>
          {this.getChip(p)}
        </span>
        <span>
          {this.getChip(a)}
        </span>
        <span>
          {this.getChip(s)}
        </span></div>
    </div>
  }

  getType = row => {
    return row["category"] && row["category"].type? row["category"].type: row["emotionType"];
  }
  dataTable = () => {
    return (

      <MaterialTable
       icons={this.tableIcons}
        title={` `}
        editable={{isDeletable: rowData => rowData}}
        columns = {[
          {title: 'Date', field: 'createTs', render: row => <Button style={{fontSize:"xx-small"}} onClick={this.handleDateClicked}>{ this.formatDate(row["createTs"]) }</Button>},
          {title: 'Type', field: 'type', render: row => <span>{this.getType(row)}</span>},
          {title: 'Event', field: 'event'},
          {title: 'Dasa Family', field: 'dasa', render: row => this.getPlanetOfAll(row["dasa"])},
        ]}

        data = {this.props.events}

        options={{
          search: true,
          padding:"dense",
          pageSize: 100,
          paging: false,
          headerStyle: {
            backgroundColor: '#01579b',
            color: '#FFF'
          }
        }}
        style={{fontSize:"small"}}
      />
    );
  }
  render() {
    return (
      <div>
        <LifeEvent  emotionTypes={this.props.emotionTypes} actions={this.props.actions} user={this.props.user} message={this.props.message} eventCategories={this.props.eventCategories}/>
        {this.dataTable()}
      </div>
    );
  }
}



export default withRouter(LifeEvents);
