export default {
  DASA_BHUKTHI: "DASA_BHUKTHI",
  GET_DASA: "GET_DASA",
  GET_PUTTHI: "GET_PUTTHI",
  GET_ANDHARAM: "GET_ANDHARAM",
	GET_SOOTCHAMAM: "GET_SOOTCHAMAM",
	GET_DASA_PUTHI_PREDICTIONS_PUTTHI: "GET_DASA_PUTHI_PREDICTIONS_PUTTHI",
	GET_DASA_PUTHI_PREDICTIONS_ANDHARAM: "GET_DASA_PUTHI_PREDICTIONS_ANDHARAM",
	GET_DASA_PUTHI_PREDICTIONS_SOOTCHAMAM: "GET_DASA_PUTHI_PREDICTIONS_SOOTCHAMAM"
};
