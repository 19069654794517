import DasaBhukthiActionConstants from "./DasaBhukthiActionConstants";

export const getDasaBhukthi = (userId) => {
  return (dispatch) =>
    dispatch({
      type: DasaBhukthiActionConstants.DASA_BHUKTHI,
      userId: userId,
    });
};


export const getDasa = (userId) => {
  return (dispatch) =>
    dispatch({
      type: DasaBhukthiActionConstants.GET_DASA,
      data:{
        userId: userId
      }
    });
};

export const getPutthi = (userId,dasa) => {
  return (dispatch) =>
    dispatch({
      type: DasaBhukthiActionConstants.GET_PUTTHI,
      data:{
        userId: userId,
        dasa
      }
    });
};



export const getAndharam = (userId, dasa, putthi) => {
  return (dispatch) =>
    dispatch({
      type: DasaBhukthiActionConstants.GET_ANDHARAM,
      data:{
        userId: userId,
        dasa,putthi
      }
    });
};


export const getSootchamam = (userId, dasa,putthi,andharam) => {
  return (dispatch) =>
    dispatch({
      type: DasaBhukthiActionConstants.GET_SOOTCHAMAM,
      data:{
        userId: userId,
        dasa,putthi,andharam
      }
    });
};

export const getDasaPuthiPredictionsPutthi = (dasa,putthi) => {
  return (dispatch) =>
    dispatch({
      type: DasaBhukthiActionConstants.GET_DASA_PUTHI_PREDICTIONS_PUTTHI,
      data:{
        dasa,putthi
      }
    });
};

export const getDasaPuthiPredictionsAndharam = (dasa,putthi) => {
  return (dispatch) =>
    dispatch({
      type: DasaBhukthiActionConstants.GET_DASA_PUTHI_PREDICTIONS_ANDHARAM,
      data:{
        dasa,putthi
      }
    });
};

export const getDasaPuthiPredictionsSootchamam = (dasa,putthi) => {
  return (dispatch) =>
    dispatch({
      type: DasaBhukthiActionConstants.GET_DASA_PUTHI_PREDICTIONS_SOOTCHAMAM,
      data:{
        dasa,putthi
      }
    });
};

