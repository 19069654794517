/* eslint-disable import/no-anonymous-default-export */
import _ from "lodash";
import moment from "moment";
import { onFinish, onError, onStart } from "../../ApiMiddleware";
import { toDoBFormat } from "../common/DateUtil";
import LifeEventActionType from "../LifeEvent/LifeEventActionType";
import SearchActionType from "../Search/SearchActionType";
import KattamActionType from "./KattamActionType";

export const initialState = () => {
  return {
    predictions: [],
    planetStrength: {},
    houseStrength: {},
    kotcharamPredictionsOfPP: [],
    kotcharam: {dob: toDoBFormat(moment())},
		dasaDetail: {
			dasa: {},
			putthiPredictions: [],
			andharamPredictions: [],
			sootchamamPredictions: []
		},
    transitDates: [],
    switcher: {
      checked: false
    },
    transitLoading: false,
    showOnlyYearlyPlanets: true,
    selectedHouse: {
      short: "",
      detail: "",
      houseNo: ""
    },
    isDeleted: ""
  };
};

export default (state = initialState(), action) => {
  switch (action.type) {
    case onFinish(KattamActionType.GET_KOTCHARAM):
      return {
        ...state,
        kotcharam: action.data,
      };
      case onFinish(KattamActionType.GET_KOTCHARAM_PLANET_STRENGTH):
      return {
        ...state,
        kotcharamPlanetStrength: action.data,
      };
      case onFinish(KattamActionType.GET_KOTCHARAM_HOUSE_STRENGTH):
        return {
          ...state,
          kotcharamHouseStrength: action.data,
        };
    case onFinish(KattamActionType.GET_ORAI):
      return {
        ...state,
        generalOrai: action.data,
      };
    case onFinish(KattamActionType.GET_RAASI_CALCULATION):
      return {
        ...state,
        sample: { data: action.data },
      };
    case onFinish(KattamActionType.GET_DASA_BY_DATE):
      return {
        ...state,
        dasaDetail: action.data,
      };
    case onError(KattamActionType.GET_DASA_BY_DATE):
      return {
        ...state,
        dasaDetail: [],

      };


    case KattamActionType.SET_SHOW_ONLY_YEARLY_PLANETS:
      return {
        ...state,
        showOnlyYearlyPlanets: !state.showOnlyYearlyPlanets,
      };

    case KattamActionType.SET_HOUSE_DETAIL:
      let d = _.get(action, "data.data", {});
      console.log("D :", d);
      return {
        ...state,
        selectedHouse: { houseDetail: d, houseNo: _.get(action, "data.houseNo", {}) }
			};

			case KattamActionType.SET_TIME_ZONE:
				let zoneId = _.get(action, "zoneId", "America/Chicago");
				return {
					...state,
					zoneId: zoneId
				};



    case onFinish(KattamActionType.RETRIEVE_PAAVA_VIVARAM):
      return {
        ...state,
        paavaVivarams: action.data
      };

    case onStart(KattamActionType.GET_TRANSIT_DATES):
      return {
        ...state,
        transitLoading: true,
      };
    case onFinish(KattamActionType.GET_TRANSIT_DATES):
      return {
        ...state,
        transitDates: action.data,
        transitLoading: false
      };

    case onFinish(KattamActionType.RETRIEVE_PLANET_STRENGTH):
      return {
        ...state,
        planetStrength: action.data
      };
      case onFinish(KattamActionType.RETRIEVE_HOUSE_STRENGTH):
      return {
        ...state,
        houseStrength: action.data
      };
    case onFinish(KattamActionType.GET_USERS):
      return {
        ...state,
        users: action.data,
      };
    case onFinish(SearchActionType.SEARCH_BY_PLANETS_ON_HOUSE):
    case onFinish(SearchActionType.SEARCH_BY_LORD_ON_HOUSE):
    case onFinish(SearchActionType.SEARCH_BY_RAASI_HOUSENO):
    case onFinish(SearchActionType.SEARCH_BY_LORD_IS_PLANET):
    case onFinish(SearchActionType.SEARCH_BY_LORD_WITH_BOND):
    case onFinish(SearchActionType.SEARCH_BY_DASA_PUTTHI):
    case onFinish(SearchActionType.SEARCH_BY_PLANET_BOND_WITH_RAASI):
      return {
        ...state,
        users: action.data,
      };
    case onFinish(KattamActionType.GET_USER_PREDICTIONS):
      return {
        ...state,
        predictions: action.data,
      };
    case onFinish(KattamActionType.GET_USER_PREDICTIONS_OF_PP):
      return {
        ...state,
        predictionsOfPP: action.data,
      };
			// case onFinish(KattamActionType.GET_USER_PREDICTIONS_OF_PP_REFERENCES):
			// 	return {
			// 		...state,
			// 		predictionsOfPP: action.data,
			// 	};


    case onFinish(KattamActionType.GET_USER_KOTCHARAM_PREDICTIONS_OF_PP):
      return {
        ...state,
        kotcharamPredictionsOfPP: action.data,
      };

    case onFinish(KattamActionType.GET_USER_KOTCHARAM_PREDICTIONS_OF_PP_FOR_PAST):
      return {
        ...state,
        kotcharamPredictionsOfPPForPast: action.data,
      };

    case onFinish(KattamActionType.GET_USER_KOTCHARAM_PREDICTIONS_OF_PP_FOR_FUTURE):
      return {
        ...state,
        kotcharamPredictionsOfPPForFuture: action.data,
      };


    case KattamActionType.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.data,
      };
    case KattamActionType.RESET_CURRENT_USER:
      return {
        ...state,
        currentUser: undefined,
      };
    case KattamActionType.DELETE_USER_JAATHAGAN:
      return {
        ...state,
      };
    case onFinish(KattamActionType.DELETE_USER_JAATHAGAN):
      return {
        ...state,
        isDeleted: action.data,
      }
    case onError(KattamActionType.DELETE_USER_JAATHAGAN):
      return {
        ...state,
      }

    case KattamActionType.SET_RAASI_VS_KATTAM:
      return {
        ...state,
        switcher: { checked: !state.switcher.checked }
      };

    default:
      return state;
  }
};
